<template>
    <div>
        <v-row class="table-actions-row">
            <v-col sm="4" cols="12" class="pl-0">
                <breadcrumbs :items="crumbs"> </breadcrumbs>
            </v-col>
            <v-col sm="8" class="d-flex justify-end align-center">
                <CButton
                    @click="
                        clearCurrentItem()
                        showEntityModal = true
                    "
                    class="add-product-modal"
                    color="info"
                >
                    <i class="la la-plus"></i>
                    Добавить агента
                </CButton>
            </v-col>
        </v-row>
        <localization :language="'ru-RU'">
            <intl :locale="'ru'">
                <Grid
                    ref="grid"
                    class="agents"
                    :resizable="true"
                    :sortable="true"
                    :filterable="true"
                    :pageable="gridPageable"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                    :take="take"
                    :skip="skip"
                    :sort="sort"
                    :page-size="pageSize"
                    :total="total"
                    :data-items="dataResult"
                    :columns="columns"
                    :expand-field="'expanded'"
                    @expandchange="expandChange"
                    @sortchange="sortChange"
                >
                    <template v-slot:actions="{ props: { dataItem } }">
                        <td>
                            <div class="actionsColumn">
                                <actions-dropdown
                                    :actions="[
                                        {
                                            text: dataItem.is_blocked
                                                ? 'Разблокировать'
                                                : 'Заблокировать',
                                            icon:
                                                'fa ' +
                                                (dataItem.is_blocked
                                                    ? 'fa-unlock'
                                                    : 'fa-lock'),
                                            handler: () =>
                                                toggleBlocked(dataItem),
                                        },
                                        {
                                            text: 'Редактировать',
                                            icon: 'la la-pencil',
                                            handler: () => {
                                                setCurrentItem(dataItem)
                                                showEntityModal = true
                                            },
                                        },
                                        {
                                            text: 'Сбросить пароль',
                                            icon: 'fa fa-key',
                                            handler: () =>
                                                resetPassword(dataItem),
                                        },
                                        {
                                            text: 'Удалить',
                                            icon: 'la la-trash',
                                            handler: () =>
                                                deleteEntity(dataItem),
                                        },
                                        {
                                            text: 'Войти под агентом',
                                            icon: 'fa fa-sign-in',
                                            handler: () => loginAs(dataItem),
                                        },
                                    ]"
                                />
                            </div>
                        </td>
                    </template>
                    <template v-slot:blocked_filter="{ methods, props }">
                        <boolean-filter
                            @change="
                                selectFilter('is_blocked', $event.value.value)
                            "
                        >
                        </boolean-filter>
                    </template>
                    <template v-slot:blocked="{ props: { dataItem } }">
                        <td>
                            <div>
                                <span
                                    class="m-badge m-badge--wide m-badge--wide"
                                    :class="{
                                        'm-badge--success': dataItem.is_blocked,
                                        'm-badge--danger': !dataItem.is_blocked,
                                    }"
                                >
                                    {{ dataItem.is_blocked ? 'ДА' : 'НЕТ' }}
                                </span>
                            </div>
                        </td>
                    </template>
                </Grid>
            </intl>
        </localization>
        <agent-modal
            v-if="showEntityModal"
            :current-item="currentItem"
            :show-dialog="showEntityModal"
            @click:outside="showEntityModal = false"
            @stored="entityStored($event)"
            @close="showEntityModal = false"
        ></agent-modal>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import TableMixin from '@/views/grid_elements/mixins/TableMixin'
    import ResizableTableMixin from '@/views/grid_elements/mixins/ResizableTableMixin'
    import { Grid } from '@progress/kendo-vue-grid'
    import {
        IntlProvider,
        LocalizationProvider,
    } from '@progress/kendo-vue-intl'
    import BooleanFilter from '@/views/components/BooleanFilter'
    import ActionsDropdown from '@/views/grid_elements/ActionsDropdown'
    import { showConfirm } from '@/lib/swal2'
    import Breadcrumbs from '@/views/components/breadcrumbs'
    import { showMessage } from '@/lib/toasted'
    import AgentCreateUpdateModal from '../modals/AgentCreateUpdateModal'
    import '@/views/grid_elements/translations'
    import api from '@/api'
    import { toDataSourceRequestString } from '@progress/kendo-data-query'

    export default {
        name: 'agencies',
        components: {
            Grid: Grid,
            breadcrumbs: Breadcrumbs,
            'boolean-filter': BooleanFilter,
            'agent-modal': AgentCreateUpdateModal,
            localization: LocalizationProvider,
            intl: IntlProvider,
            ActionsDropdown,
        },
        mixins: [TableMixin, ResizableTableMixin],
        mounted() {
            this.getData()
        },
        data() {
            return {
                skip: 0,
                take: 10,
                gridPageable: { pageSizes: true },
                pageSize: 10,
                total: 7,
                expandedItems: [],
                columns: [
                    {
                        field: 'id',
                        title: 'Действия',
                        cell: 'actions',
                        filterable: false,
                        sortable: false,
                    },
                    {
                        field: 'email',
                        title: 'E-mail',
                    },
                    {
                        field: 'name',
                        title: 'ФИО',
                    },
                    {
                        field: 'is_blocked',
                        title: 'Заблокирован?',
                        filterCell: 'blocked_filter',
                        cell: 'blocked',
                        filter: 'boolean',
                    },
                    {
                        field: 'agency_name',
                        title: 'Агенство',
                    },
                    {
                        field: 'phone_number',
                        title: 'Телефон',
                    },
                ],
            }
        },
        methods: {
            ...mapActions('account', ['loginAsAgent']),
            // fetchData: async () => {
            //   return Promise.resolve({
            //     data: {
            //       "status": "ok",
            //       "data": {
            //         "items": [{
            //           "id": "205bd388-651f-427a-dcc4-08d68053ea37",
            //           "email": "gevorgyan@tionit.com",
            //           "name": "FIOtestt",
            //           "phone_number": null,
            //           "is_blocked": false,
            //           "agency_id": "a4abc525-ad26-4336-2cf6-08d65ba9c29a",
            //           "agency_name": "Сувенирная продукция (тест)"
            //         }, {
            //           "id": "41c5e81e-734b-4e41-b6a8-08d8f038f201",
            //           "email": "sardaryan1@tionit.com",
            //           "name": "Nowoe",
            //           "phone_number": null,
            //           "is_blocked": false,
            //           "agency_id": "3cc481b0-d83e-4177-35a7-08d8f038c3a9",
            //           "agency_name": "Новое"
            //         }, {
            //           "id": "794a044b-cd66-4e7a-dcc3-08d68053ea37",
            //           "email": "armgevorgyan98@yandex.ru",
            //           "name": "Svetlana",
            //           "phone_number": null,
            //           "is_blocked": false,
            //           "agency_id": "89caa763-690d-4f02-8c65-08d676f05544",
            //           "agency_name": "ООО «Типография «ПиКвадрат»"
            //         }, {
            //           "id": "5f83bd30-567b-438b-b52c-08d6f58e4eef",
            //           "email": "sardaryan@tionit.com",
            //           "name": "test",
            //           "phone_number": null,
            //           "is_blocked": false,
            //           "agency_id": "50d248b0-de6f-4b4d-8491-bff1d810b714",
            //           "agency_name": "Компания «ЦДК» (поездки тест)"
            //         }, {
            //           "id": "aaa947a1-188c-4b74-8ef2-08d93bc37f7c",
            //           "email": "test43432@test.com",
            //           "name": "TEST",
            //           "phone_number": null,
            //           "is_blocked": false,
            //           "agency_id": "108be86a-fd0c-455d-2cf5-08d65ba9c29a",
            //           "agency_name": "Агентство для полиграфии (тест)"
            //         }, {
            //           "id": "6d28c411-2798-45f0-fe7c-08d6f3d03e85",
            //           "email": "starikova@cdk-bt.ru",
            //           "name": "Vlada Starikova",
            //           "phone_number": null,
            //           "is_blocked": false,
            //           "agency_id": "50d248b0-de6f-4b4d-8491-bff1d810b714",
            //           "agency_name": "Компания «ЦДК» (поездки тест)"
            //         }, {
            //           "id": "91ba778e-453e-45b4-8898-08d58012bf58",
            //           "email": "test-lukoil@yandex.ru",
            //           "name": "Агент ООО \"Сувенирная продукция\"",
            //           "phone_number": null,
            //           "is_blocked": false,
            //           "agency_id": "a4abc525-ad26-4336-2cf6-08d65ba9c29a",
            //           "agency_name": "Сувенирная продукция (тест)"
            //         }, {
            //           "id": "a6cb1031-dce0-4fae-cc8e-08d568ab289a",
            //           "email": "gureev1988@gmail.com",
            //           "name": "Кирилл Гуреев",
            //           "phone_number": null,
            //           "is_blocked": true,
            //           "agency_id": "108be86a-fd0c-455d-2cf5-08d65ba9c29a",
            //           "agency_name": "Агентство для полиграфии (тест)"
            //         }, {
            //           "id": "46e9fb3e-ad12-4230-7136-08d71a7a786c",
            //           "email": "svetlanna.sardaryan@gmail.com",
            //           "name": "Светлана С",
            //           "phone_number": null,
            //           "is_blocked": false,
            //           "agency_id": "89caa763-690d-4f02-8c65-08d676f05544",
            //           "agency_name": "ООО «Типография «ПиКвадрат»"
            //         }, {
            //           "id": "88656c7d-447f-493c-de3b-08d89c5c24ae",
            //           "email": "almira.work2@mail.ru",
            //           "name": "Тестовый сотрудник",
            //           "phone_number": null,
            //           "is_blocked": false,
            //           "agency_id": "01047bb0-bdfa-46a9-66fb-08d89c24073f",
            //           "agency_name": "Маркетинг ООО «ЛЛК-Интернешнл»"
            //         }],
            //         "total": 12
            //       }
            //     }
            //   });
            // },
            fetchData: api.agencies.getAgents,
            prepareFetchParams() {
                return [
                    this.page,
                    this.take,
                    toDataSourceRequestString({
                        filter: this.filter,
                        sort: this.sort,
                    }),
                ]
            },
            getActions() {
                return {}
            },
            async deleteEntity(item) {
                const { isConfirmed } = await showConfirm(
                    'Вы действительно хотите удалить данный элемент?'
                )

                if (isConfirmed) {
                    const {
                        data: { status, message },
                    } = await api.agencies.deleteAgent(item.id)
                    showMessage(status, message)

                    await this.getData()
                }
            },
            async toggleBlocked(item) {
                const { is_blocked, id } = item
                const message = is_blocked
                    ? 'Вы действительно хотите разблокировать данного агента?'
                    : 'Вы действительно хотите заблокировать данного агента?'
                const { isConfirmed } = await showConfirm(message)

                if (isConfirmed) {
                    const {
                        data: { status, message },
                    } = await api.agencies.toggleAgentProperty(id, 'is-blocked')
                    showMessage(status, message)

                    await this.getData()
                }
            },
            async resetPassword(item) {
                const { id } = item
                const message =
                    'Вы действительно хотите сгенерировать новый пароль и отправить его на e-mail агента?'
                const { isConfirmed } = await showConfirm(message)

                if (isConfirmed) {
                    const {
                        data: { status, message },
                    } = await api.agencies.resetAgentPassword(id)
                    showMessage(status, message)
                }
            },
            async loginAs(item) {
                const { id } = item
                const message =
                    'Вы действительно хотите завершить текущий сеанс и авторизоваться под агентом?'
                const { isConfirmed } = await showConfirm(message)

                if (isConfirmed) {
                    await this.loginAsAgent(id)
                }
            },
        },
    }
</script>

<style scoped></style>
