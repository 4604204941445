<template>
  <ValidationObserver slim v-slot="{ invalid, untouched, handleSubmit }">
    <v-dialog
        :value="showDialog"
        :persistent="false"
        width="600"
        @input="closeDialog"
    >
      <v-card>
        <v-card-title class="headline">
          <span><i class="fa fa-user"></i> {{ currentItem ? 'Редактирование агента' : 'Добавить агента' }}</span>
        </v-card-title>

        <v-card-text>
          <form>
            <v-layout column>
              <ValidationProvider name="agency_id" rules="required" v-slot="{invalid, validated}">
                <v-select
                    :disabled="id"
                    v-model="agency_id"
                    :items="agenciesListShort"
                    item-value="id"
                    item-text="name"
                    label="Агентство *"
                    hide-details="auto"
                    required
                    :error="invalid && validated"
                ></v-select>
              </ValidationProvider>
              <ValidationProvider name="email" rules="required|email" v-slot="{invalid, validated}">
                <v-text-field
                    :error="invalid && validated"
                    label="E-mail *"
                    hide-details="auto"
                    v-model="email"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider name="name" rules="required|alpha_spaces:ru" v-slot="{invalid, validated}">
                <v-text-field
                    :error="invalid && validated"
                    label="ФИО *"
                    hide-details="auto"
                    v-model="name"
                ></v-text-field>
              </ValidationProvider>
              <v-text-field
                  :error="invalid && validated"
                  label="Номер телефона"
                  hide-details="auto"
                  v-model="phone_number"
              ></v-text-field>
            </v-layout>
          </form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <CButton class="mr-3" color="secondary" @click="$emit('close')">Отмена</CButton>
          <CButton :disabled="invalid" @click="handleSubmit(submit)" color="success">
            <i class="fa fa-save mr-2"></i>
            <span>Сохранить</span>
          </CButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </ValidationObserver>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import EntityModalMixin from '@/views/grid_elements/mixins/EntityModalMixin';
import api from '@/api';

export default {
  name: "agency-modal",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  mixins: [EntityModalMixin],
  async created() {
    await this.fetchAgencyListShort();
  },
  data() {
    let state = {
      id: null,
      agency_id: null,
      email: '',
      name: '',
      phone_number: ''
    };

    if (this.currentItem) {
      state = {
        ...state,
        ...this.currentItem
      }
    }

    return state;
  },
  methods: {
    createEntity: api.agencies.createAgent,
    updateEntity: api.agencies.updateAgent,
    ...mapActions('agency', ['fetchAgencyListShort']),
  },
  computed: {
    ...mapState('agency', ['agenciesListShort']),
    data() {
      return {
        agency_id: this.agency_id,
        email: this.email,
        name: this.name,
        phone_number: this.phone_number
      }
    }
  }
}
</script>
