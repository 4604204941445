var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"table-actions-row"},[_c('v-col',{staticClass:"pl-0",attrs:{"sm":"4","cols":"12"}},[_c('breadcrumbs',{attrs:{"items":_vm.crumbs}})],1),_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"sm":"8"}},[_c('CButton',{staticClass:"add-product-modal",attrs:{"color":"info"},on:{"click":function($event){_vm.clearCurrentItem()
                    _vm.showEntityModal = true}}},[_c('i',{staticClass:"la la-plus"}),_vm._v(" Добавить агента ")])],1)],1),_c('localization',{attrs:{"language":'ru-RU'}},[_c('intl',{attrs:{"locale":'ru'}},[_c('Grid',{ref:"grid",staticClass:"agents",attrs:{"resizable":true,"sortable":true,"filterable":true,"pageable":_vm.gridPageable,"filter":_vm.filter,"take":_vm.take,"skip":_vm.skip,"sort":_vm.sort,"page-size":_vm.pageSize,"total":_vm.total,"data-items":_vm.dataResult,"columns":_vm.columns,"expand-field":'expanded'},on:{"datastatechange":_vm.dataStateChange,"expandchange":_vm.expandChange,"sortchange":_vm.sortChange},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
                    var dataItem = ref.props.dataItem;
return [_c('td',[_c('div',{staticClass:"actionsColumn"},[_c('actions-dropdown',{attrs:{"actions":[
                                    {
                                        text: dataItem.is_blocked
                                            ? 'Разблокировать'
                                            : 'Заблокировать',
                                        icon:
                                            'fa ' +
                                            (dataItem.is_blocked
                                                ? 'fa-unlock'
                                                : 'fa-lock'),
                                        handler: function () { return _vm.toggleBlocked(dataItem); },
                                    },
                                    {
                                        text: 'Редактировать',
                                        icon: 'la la-pencil',
                                        handler: function () {
                                            _vm.setCurrentItem(dataItem)
                                            _vm.showEntityModal = true
                                        },
                                    },
                                    {
                                        text: 'Сбросить пароль',
                                        icon: 'fa fa-key',
                                        handler: function () { return _vm.resetPassword(dataItem); },
                                    },
                                    {
                                        text: 'Удалить',
                                        icon: 'la la-trash',
                                        handler: function () { return _vm.deleteEntity(dataItem); },
                                    },
                                    {
                                        text: 'Войти под агентом',
                                        icon: 'fa fa-sign-in',
                                        handler: function () { return _vm.loginAs(dataItem); },
                                    } ]}})],1)])]}},{key:"blocked_filter",fn:function(ref){
                                var methods = ref.methods;
                                var props = ref.props;
return [_c('boolean-filter',{on:{"change":function($event){return _vm.selectFilter('is_blocked', $event.value.value)}}})]}},{key:"blocked",fn:function(ref){
                                var dataItem = ref.props.dataItem;
return [_c('td',[_c('div',[_c('span',{staticClass:"m-badge m-badge--wide m-badge--wide",class:{
                                    'm-badge--success': dataItem.is_blocked,
                                    'm-badge--danger': !dataItem.is_blocked,
                                }},[_vm._v(" "+_vm._s(dataItem.is_blocked ? 'ДА' : 'НЕТ')+" ")])])])]}}])})],1)],1),(_vm.showEntityModal)?_c('agent-modal',{attrs:{"current-item":_vm.currentItem,"show-dialog":_vm.showEntityModal},on:{"click:outside":function($event){_vm.showEntityModal = false},"stored":function($event){return _vm.entityStored($event)},"close":function($event){_vm.showEntityModal = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }